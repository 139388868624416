import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEnvironmentRoutine } from 'src/state/environment/reducer';
import { getEnvironmentSelector } from 'src/state/environment/selectors';
import OncoreLoader from '@oncore/ui/shared/OncoreLoader';

type LoaderProps = PropsWithChildren & {
  loading?: ReactNode;
}

function Loader(props: LoaderProps) {
  const {
    children,
  } = props;
  const { isReady } = useSelector(getEnvironmentSelector());
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isReady) {
      dispatch(setEnvironmentRoutine.trigger());
    }
  }, [isReady]);

  if (!isReady) {
    return (
      <OncoreLoader
        variant="splash"
        loadingText="Loading environment..."/>
    );
  }

  return children;
}

export default Loader;
