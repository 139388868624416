import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import styled from 'styled-components';

import Layout from '@oncore/ui/Layout';
import ChromeTopBar from './ChromeTopBar.tsx';
import ChromeSideBar from './ChromeSideBar.tsx';
import OncoreLoader from '@oncore/ui/shared/OncoreLoader';

const StyledLayout = styled(Layout)`
  height: 100vh;
  width: 100vw;
`;

const Chrome = () => (
  <StyledLayout
    product="omp"
    topbar={<ChromeTopBar />}
    sidebar={<ChromeSideBar />}
    content={(
      <Suspense fallback={(
        <OncoreLoader/>
      )}>
        <Outlet />
      </Suspense>
    )}
  />
);

export default Chrome;
