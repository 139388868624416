import { RouteObject } from 'react-router-dom';
import { 
  lazy,
  createElement,
} from 'react';

import links from '../links';

const UserPage = lazy(() => import('src/pages/User/UserPage'));
const UserIndexPage = lazy(() => import('src/pages/User/UserIndexPage'));
const UserDetailsPage = lazy(() => import('src/pages/UserDetails/UserDetailsPage'));

const userRoutes: RouteObject = {
  path: links.user({
    params: {
      userID: ':userID',
    },
  }),
  element: createElement(UserPage),
  children: [
    {
      path: '',
      element: createElement(UserIndexPage),
    },
    {
      path: links.userDetails({
        params: {
          userID: ':userID',
        },
      }),
      element: createElement(UserDetailsPage),
    },
  ],
};

export default userRoutes;
