import { 
  Suspense,
  ReactNode,
  LazyExoticComponent,
  createElement
} from 'react';
import OncoreLoader from '@oncore/ui/shared/OncoreLoader';

export const createRootRouteElement = (element: LazyExoticComponent<() => ReactNode>) => {

  return (
    <Suspense fallback={(
      <OncoreLoader variant="splash"/>
    )}>
      {createElement(element)}
    </Suspense>
  );
};
