import {
  isRouteErrorResponse,
  useRouteError, 
  useNavigate
} from 'react-router-dom';

import {
  PageNotFound,
  SomethingWentWrong
} from '@oncore/ui/ErrorPages';
import links from 'src/components/AppRouter/links';

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  const handleRefresh = () => {
    location.reload();
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGoToLogin = () => {
    navigate(links.logout());
  };

  if (isRouteErrorResponse(error)) {
    return (
      <PageNotFound
        onGoBackClick={handleGoBack}
        onGotoLoginClick={handleGoToLogin}
      />
    );
  }

  return (
    <SomethingWentWrong
      onRefreshClick={handleRefresh}
    />
  );
};

export default ErrorPage;
