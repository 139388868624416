import { RouteObject } from 'react-router-dom';
import { 
  lazy,
  createElement,
} from 'react';

import links from '../links';

const TenantPage = lazy(() => import('src/pages/Tenant/TenantPage'));
const TenantIndexPage = lazy(() => import('src/pages/Tenant/TenantIndexPage'));
const TenantDetailsPage = lazy(() => import('src/pages/TenantDetails/TenantDetailsPage'));
const TenantFeaturesPage = lazy(() => import('src/pages/TenantFeatures/TenantFeaturesPage'));
const TenantSettingsPage = lazy(() => import('src/pages/TenantSettings/TenantSettingsPage'));
const TenantTimesheetsPage = lazy(() => import('src/pages/TenantTimesheets/TenantTimesheetsPage'));
const TenantContractsPage = lazy(() => import('src/pages/TenantContracts/TenantContractsPage'));
const TenantGroupsPage = lazy(() => import('src/pages/TenantGroups/TenantGroupsPage'));
const TenantUsersPage = lazy(() => import('src/pages/TenantUsers/TenantUsersPage'));

const tenantRoute: RouteObject = {
  path: links.tenant({
    params: {
      tenantID: ':tenantID',
    },
  }),
  element: createElement(TenantPage),
  children: [
    {
      path: '',
      element: createElement(TenantIndexPage),
    },
    {
      path: links.tenantDetails({
        params: {
          tenantID: ':tenantID',
        },
      }),
      element: createElement(TenantDetailsPage),
    },
    {
      path: links.tenantFeatures({
        params: {
          tenantID: ':tenantID',
        },
      }),
      element: createElement(TenantFeaturesPage),
    },
    {
      path: links.tenantSettings({
        params: {
          tenantID: ':tenantID',
        },
      }),
      element: createElement(TenantSettingsPage),
    },
    {
      path: links.tenantTimesheets({
        params: {
          tenantID: ':tenantID',
        },
      }),
      element: createElement(TenantTimesheetsPage),
    },
    {
      path: links.tenantContracts({
        params: {
          tenantID: ':tenantID',
        },
      }),
      element: createElement(TenantContractsPage),
    },
    {
      path: links.tenantGroups({
        params: {
          tenantID: ':tenantID',
        },
      }),
      element: createElement(TenantGroupsPage),
    },
    {
      path: links.tenantUsers({
        params: {
          tenantID: ':tenantID',
        },
      }),
      element: createElement(TenantUsersPage),
    },
  ],
};

export default tenantRoute;
