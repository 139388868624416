
type TenantParams = {
  tenantID: string;
};

type UserParams = {
  userID: string;
};

export type NavigationContext = {
  tenant: {
    params: TenantParams;
  };
  tenantContracts: {
    params: TenantParams;
  };
  tenantDetails: {
    params: TenantParams;
  };
  tenantFeatures: {
    params: TenantParams;
  };
  tenantGroups: {
    params: TenantParams;
  };
  tenantSettings: {
    params: TenantParams;
  };
  tenantTimesheets: {
    params: TenantParams;
  };
  tenantUsers: {
    params: TenantParams;
  };
  user: {
    params: UserParams;
  };
  userDetails: {
    params: UserParams;
  };
};

const links = {
  logout: () => {
    return '/logout' as const;
  },
  tenants: () => {
    return '/tenants' as const;
  },
  tenantNew: () => {
    return `${links.tenants()}/new` as const;
  },
  tenant: (context: NavigationContext['tenant']) => {
    return `${links.tenants()}/${context.params.tenantID}` as const;
  },
  tenantContracts: (context: NavigationContext['tenantContracts']) => {
    return `${links.tenant(context)}/contracts` as const;
  },
  tenantDetails: (context: NavigationContext['tenantDetails']) => {
    return `${links.tenant(context)}/details` as const;
  },
  tenantFeatures: (context: NavigationContext['tenantFeatures']) => {
    return `${links.tenant(context)}/features` as const;
  },
  tenantGroups: (context: NavigationContext['tenantGroups']) => {
    return `${links.tenant(context)}/groups` as const;
  },
  tenantSettings: (context: NavigationContext['tenantSettings']) => {
    return `${links.tenant(context)}/settings` as const;
  },
  tenantTimesheets: (context: NavigationContext['tenantTimesheets']) => {
    return `${links.tenant(context)}/timesheets` as const;
  },
  tenantUsers: (context: NavigationContext['tenantUsers']) => {
    return `${links.tenant(context)}/users` as const;
  },
  users: () => {
    return '/users' as const;
  },
  user: (context: NavigationContext['user']) => {
    return `${links.users()}/${context.params.userID}` as const;
  },
  userDetails: (context: NavigationContext['userDetails']) => {
    return `${links.user(context)}/details` as const;
  },
};

export default links;
