import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useMemo } from 'react';
import rootRoutes from './routes/rootRoutes';

const AppRouter = () => {
  const router = useMemo(() => {
    return createBrowserRouter(rootRoutes);
  }, []);

  return (
    <RouterProvider router={router}/>
  );
};

export default AppRouter;
