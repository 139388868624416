import t from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-C9VCECeM.js";
import { getSpacings as e, getRadii as r, getZIndexes as i, getPaletteBorder as n, getPaletteBackground as l, getBreakpointMediaQuery as a, getPaletteForeground as d } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-B6kdoens.js";
import "../../CssBaseline-CQMfs2gH.js";
import { I as p } from "../../IconButton-D51uKWnW.js";
const $ = t.div`
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  gap: ${e("lg")};
  padding: ${e("xl")};
  border-radius: ${r("xl")};
  z-index: ${i("tooltip")};
  border: 1px solid ${n("primary")};
  background-color: ${l("primary.alt")};
  flex-direction: row;
  align-items: ${(o) => o.$hasChildren ? "flex-start" : "center"};
  ${a("max", "lg")} {
    flex-direction: column;
    align-items: flex-start;
  }
`, B = t(p)`
  top: 0;
  right: 0;
  position: absolute;
  color: ${d("quinary.default")};
`, b = t.div`
  display: flex;
  flex-direction: column;
`;
export {
  B as StyledBannerCloseIconButton,
  $ as StyledBannerContainer,
  b as StyledBannerContent
};
