import { createContext as e, useContext as t } from "react";
const o = e({
  openBanner: () => {
  }
}), a = () => {
  const { openBanner: n } = t(o);
  return { openBanner: n };
};
export {
  o as B,
  a as u
};
