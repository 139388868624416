import { i as e } from "../../isExhaustive-RFXKPwPh.js";
import "react-phone-number-input";
const s = (r) => {
  switch (r) {
    case "neutral":
      return { variant: "info-circle", color: "gray" };
    case "error":
      return { variant: "info-circle", color: "error" };
    case "success":
      return { variant: "check-circle-broken", color: "success" };
    default:
      return e(r);
  }
};
export {
  s as getBannerIconProps
};
