import { lazy as r } from "react";
const t = {
  // We anticipate this to grow very big. For ease of maintenance,
  // it is recommended to maintain alphabetical sorting of the keys
  "alert-circle": r(() => import("../../alert-circle-BhGEpNex.js")),
  "alert-triangle": r(() => import("../../alert-triangle-Ch29ZHdm.js")),
  "arrow-left": r(() => import("../../arrow-left-5c8GPyTD.js")),
  "bar-chart-square": r(
    () => import("../../bar-chart-square-BGhVxPWd.js")
  ),
  briefcase: r(() => import("../../briefcase-01-DtcHQfY3.js")),
  building: r(() => import("../../building-03-CHEhyd5q.js")),
  calendar: r(() => import("../../calendar-BuCZVUcw.js")),
  "calendar-plus": r(() => import("../../calendar-plus-CWzr8QBD.js")),
  check: r(() => import("../../check-pOa13kRf.js")),
  "check-circle": r(() => import("../../check-circle-BEeNCF0f.js")),
  "check-circle-broken": r(
    () => import("../../check-circle-broken-BdM4Wvrs.js")
  ),
  "chevron-down": r(() => import("../../chevron-down-DMHTq3EF.js")),
  "chevron-left": r(() => import("../../chevron-left-CUtFU7KG.js")),
  "chevron-right": r(() => import("../../chevron-right-CSJfjlEV.js")),
  "chevron-up": r(() => import("../../chevron-up-B6jInKBv.js")),
  "chevron-selector-horizontal": r(() => import("../../chevron-selector-horizontal-hll5UeQZ.js")),
  "chevron-selector-vertical": r(() => import("../../chevron-selector-vertical-5zWZBOpj.js")),
  clock: r(() => import("../../clock-Dfke03kp.js")),
  "code-01": r(() => import("../../code-01-BP9lB1Zm.js")),
  "code-02": r(() => import("../../code-02-Dl3IxEXa.js")),
  "copy-01": r(() => import("../../copy-01-BqN8yvbH.js")),
  close: r(() => import("../../x-close-DGa3BIug.js")),
  "currency-dollar": r(() => import("../../currency-dollar-DTMpl39i.js")),
  document: r(() => import("../../document-DXNTM6Zb.js")),
  "dots-vertical": r(() => import("../../dots-vertical-CgSwaOSu.js")),
  "download-01": r(() => import("../../download-01-E34PZkBE.js")),
  "download-02": r(() => import("../../download-02-B1vt0Hox.js")),
  "download-03": r(() => import("../../download-03-Txa66OXB.js")),
  "download-04": r(() => import("../../download-04-j-MQhDBI.js")),
  "edit-05": r(() => import("../../edit-05-C6QFNa_8.js")),
  "file-attachment": r(() => import("../../file-attachment-CzfEZ9YB.js")),
  "film-01": r(() => import("../../film-01-BdasV4Ec.js")),
  "filter-lines": r(() => import("../../filter-lines-DUGYnGNd.js")),
  folder: r(() => import("../../folder-0Jfowvdz.js")),
  home: r(() => import("../../home-DrFFaLdX.js")),
  "image-05": r(() => import("../../image-05-7wi00FZr.js")),
  "info-circle": r(() => import("../../info-circle-DgvF6mGx.js")),
  "log-out": r(() => import("../../log-out-01-DppvYjMF.js")),
  "marker-pin": r(() => import("../../marker-pin-JFtwCt5Y.js")),
  menu: r(() => import("../../menu-02-BvfhjtPC.js")),
  minus: r(() => import("../../minus-Cv7JH9iK.js")),
  "minus-circle": r(() => import("../../minus-circle-C-kWihpC.js")),
  "music-note-01": r(() => import("../../music-note-01-B-ZQNNgw.js")),
  "percent-01": r(() => import("../../percent-01-DWNCdxlj.js")),
  "play-circle": r(() => import("../../play-circle-B-11GYYx.js")),
  plus: r(() => import("../../plus-PiQLj9s5.js")),
  "plus-circle": r(() => import("../../plus-circle-CuYhnwqL.js")),
  "portable-document-format": r(() => import("../../portable-document-format-ByYSxGQx.js")),
  receipt: r(() => import("../../receipt-C5BBdKVd.js")),
  "receipt-check": r(() => import("../../receipt-check-iugmdRuY.js")),
  "reverse-left": r(() => import("../../reverse-left-DYBCtr8r.js")),
  "search-lg": r(() => import("../../search-lg-CVqlQDVP.js")),
  "send-03": r(() => import("../../send-03-CuMzgCIQ.js")),
  "slash-circle-02": r(() => import("../../slash-circle-02-UmX2WHfY.js")),
  spreadsheet: r(() => import("../../spreadsheet-BUs_Nuce.js")),
  "stars-02": r(() => import("../../stars-02-BKE2zD98.js")),
  "toggle-03": r(() => import("../../toggle-03-right-DkUv5yCX.js")),
  "trash-01": r(() => import("../../trash-01-DVyHgkH6.js")),
  "trash-02": r(() => import("../../trash-02-CFyut0fV.js")),
  "trash-03": r(() => import("../../trash-03-B_g0t-tj.js")),
  "trash-04": r(() => import("../../trash-04-BlLUpTbG.js")),
  "upload-cloud-01": r(() => import("../../upload-cloud-01-DHVDM2CL.js")),
  "upload-cloud-02": r(() => import("../../upload-cloud-02-Dp2bgj6K.js")),
  user: r(() => import("../../user-01-hr14Logs.js")),
  users: r(() => import("../../users-01-BG7rOzcw.js")),
  "users-2": r(() => import("../../users-02-BHyFXWzF.js")),
  "user-plus": r(() => import("../../user-plus-B9KJgnMS.js")),
  "user-check": r(() => import("../../user-check-BVsdPFNF.js")),
  "user-square": r(() => import("../../user-square-FrxCpQHo.js")),
  "x-circle": r(() => import("../../x-circle-BjQNSq7c.js")),
  "x-circle-full": r(() => import("../../x-circle-full-C5srnibp.js"))
};
export {
  t as default
};
