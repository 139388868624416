import { 
  RouteObject,
  redirect,
} from 'react-router-dom';
import { 
  lazy,
  createElement,
} from 'react';

import links from '../links';
import Chrome from 'src/components/Chrome';
import ErrorPage from 'src/components/page-templates/ErrorPage';
import { createRootRouteElement } from '../utils';
import tenantRoutes from './tenantRoutes';
import userRoutes from './userRoutes';

const LogoutPage = lazy(() => import('src/pages/Logout/LogoutPage'));

const TenantsPage = lazy(() => import('src/pages/Tenants/TenantsPage'));
const TenantNewPage = lazy(() => import('src/pages/TenantNew/TenantNewPage'));

const UsersPage = lazy(() => import('src/pages//Users/UsersPage'));

const mainRoutes: RouteObject = {
  element: createElement(Chrome),
  errorElement: createElement(ErrorPage),
  children: [
    {
      path: links.tenants(),
      element: createElement(TenantsPage),
    },
    tenantRoutes,
    {
      path: links.users(),
      element: createElement(UsersPage),
    },
    userRoutes,
  ],
};

const rootRoutes: RouteObject[] = [
  mainRoutes,
  {
    path: '/',
    loader: () => redirect(links.tenants())
  },
  {
    path: links.logout(),
    element: createRootRouteElement(LogoutPage),
  },
  {
    path: links.tenantNew(),
    element: createRootRouteElement(TenantNewPage),
  },
];

export default rootRoutes;
